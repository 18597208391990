import React from 'react';
import styled from 'styled-components';
import facebook from '../assets/images/facebook.svg';
import whatsapp from '../assets/images/whatsapp.svg';

const HeaderStyles = styled.div`
  padding-top: 1rem;
  padding-bottom: 2rem;
  text-align: center;
  position: relative;
  //background: linear-gradient(transparent, var(--color_6));
  background-color: white;

  a:hover {
    transform: scale(1.2, 1.2);
  }

  @media (max-width: 800px) {
    font-size: 1.5rem;
  }

  .icon-links-container {
    position: absolute;
    top: 1rem;
    right: 4rem;
    display: grid;
    grid-gap: 1rem;
    justify-items: end;
    border-left: 1px solid lightgray;
    padding-left: 1rem;
    @media (max-width: 800px) {
      top: 1rem;
      right: 2.5rem;
    };
  }
`;

export default function Header() {
  return (
    <HeaderStyles>
      <div className="icon-links-container">
        <a className="link-icono" href="https://www.facebook.com/psicologa.en.flores.laura"><img src={facebook} align="center" alt="Facebook" height="20px"/></a>
        <a className="link-icono" href="https://wa.me/5491134351210"><img src={whatsapp} align="center" alt="Whatsapp" height="20px"/></a>
      </div>
      <h1>Lic. Laura Milberg</h1>
      <h2>Psicóloga</h2>
    </HeaderStyles>
  );
}